<template>
  <div style="margin-top: 40px">
    <GroupProccess :selectedItems="selected_requests"
                   @filterData="handleFilter"
                   :selectedTab="selectedTab"
                   :selectedTabTop="selectedTabTop" />

    <template>
      <v-row>
        <v-col cols="12" sm="7" md="7">

          <div style="display: flex;margin-bottom: 21px;">

            <div class="text-center right_bouttons" style="position: relative">
              <v-btn
                  class="ma-2 white--text filter__border"
                  :style="{
                'background-color': selectedTabTop === 0 ? '#7b047d' : '#8E8E8E',
                'min-width' : '235px'
              }"
                  @click="tabsFilterTop(0)"
              >
              <span :style="{ color: selectedTabTop === 0 ? 'white' : 'white' }">
               سلبي
              </span>
                <div :class="[selectedTab === 1 && switchColor.classificationTypeNegative ? 'down__arrow' : '']"></div>
              </v-btn>
            </div>


            <div class="text-center right_bouttons" style="position: relative">
              <v-btn
                  class="ma-2 white--text filter__border"
                  :style="{
                'background-color': selectedTabTop === 1 ? '#7b047d' : '#8E8E8E',
                'min-width' : '235px'
              }"
                  @click="tabsFilterTop(1)"
              >
              <span :style="{ color: selectedTabTop === 1 ? 'white' : 'white' }">
                إيجابي
              </span>
                <div :class="[selectedTabTop === 1 && switchColor.classificationTypePositive ? 'down__arrow' : '']"></div>
              </v-btn>
            </div>


          </div>
          <div style="display: flex;margin-bottom: 21px;">

            <div class="text-center right_bouttons" style="position: relative">
              <v-btn
                  class="ma-2 white--text filter__border"
                  :style="{
                'background-color': selectedTab === 0 ? '#3F0E40' : '#8E8E8E',
                'min-width' : '150px'
              }"
                  @click="tabsFilter(0)"
              >
              <span :style="{ color: selectedTab === 0 ? 'white' : 'white' }">
                الكل
              </span>
                <div :class="[selectedTab === 0 && switchColor.getAllRates ? 'down__arrow' : '']"></div>
              </v-btn>
            </div>

            <div class="text-center right_bouttons" style="position: relative">
              <v-btn
                  class="ma-2 white--text filter__border"
                  :style="{
                'background-color': selectedTab === 1 ? '#3F0E40' : '#8E8E8E',
                'min-width' : '150px'
              }"
                  @click="tabsFilter(1)"
              >
              <span :style="{ color: selectedTab === 1 ? 'white' : 'white' }">
                تحت المعالجة
              </span>
                <div :class="[selectedTab === 1 && switchColor.notProccessedFromSalesManager ? 'down__arrow' : '']"></div>
              </v-btn>
            </div>


            <div class="text-center right_bouttons" style="position: relative">
              <v-btn
                  class="ma-2 white--text filter__border"
                  :style="{
                'background-color': selectedTab === 2 ? '#3F0E40' : '#8E8E8E',
                'min-width' : '150px'
              }"
                  @click="tabsFilter(2)"
              >
              <span :style="{ color: selectedTab === 2 ? 'white' : 'white' }">

                تمت معالجتة
              </span>
                <div :class="[selectedTab === 2 && switchColor.ProccessedFromSalesManager ? 'down__arrow' : '']"></div>
              </v-btn>
            </div>


          </div>



        </v-col>

      </v-row>
    </template>

    <new-table-component
        :showSelect="true"
        itemKey="id"
        :selectedRows.sync="selected_requests"
        :headers="getHeaders"
        :pagination="pagination"
        :limit="$store.state.counterOfShow"
        :items="tableData"
        :loading="loading"
        v-model="page"
    >
      <template slot="item.stars" slot-scope="props">
        <div class="text-center">
          <v-rating
              v-model="props.item.stars"
              bg-color="orange-lighten-1"
              color="yellow"
              readonly
          ></v-rating>
        </div>
      </template>


      <template slot="item.request.customer.name" slot-scope="props">
        <span v-if="!props.item.request?.customer?.name"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
                props.item.request?.customer?.name.substring(0, 10) + "..."
              }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.request?.customer?.name }}
          </div>
        </v-tooltip>
      </template>
      <template slot="item.request.req_date" slot-scope="props">
        <span v-if="!props.item.request?.req_date"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
                props.item.request?.req_date
              }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.request?.req_date}}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.request.user.name" slot-scope="props">
        <span v-if="!props.item.request?.user?.name"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
                props.item.request?.user?.name.substring(0, 10) + "..."
              }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.request?.user?.name }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.request.comment" slot-scope="props">
        <span v-if="!props.item.request?.comment"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
                props.item.request?.comment.substring(0, 10) + "..."
              }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.request?.comment }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.request.comment" slot-scope="props">
        <span v-if="!props.item.request?.comment"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
                props.item.request?.comment.substring(0, 10) + "..."
              }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.request?.comment }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.request.quacomment" slot-scope="props">
        <span v-if="!props.item.request?.quacomment"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
                props.item.request?.quacomment.substring(0, 10) + "..."
              }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.request?.quacomment }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.request.class_id_agent_text" slot-scope="props">
        <span v-if="!props.item.request?.class_id_agent_text"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
                props.item.request?.class_id_agent_text.substring(0, 10) + "..."
              }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.request?.class_id_agent_text }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.request.source_text" slot-scope="props">
        <span v-if="!props.item.request?.source_text"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
                props.item.request?.source_text.substring(0, 10) + "..."
              }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.request?.source_text }}
          </div>
        </v-tooltip>
      </template>

      <template slot="item.source" slot-scope="props">
        <span v-if="!props.item.source"></span>
        <v-tooltip bottom v-else>
          <template v-slot:activator="{ on }">
            <span v-on="on">{{
                props.item.source.substring(0, 10) + "..."
              }}</span>
          </template>
          <div class="font-weight-bold">
            {{ props.item.source }}
          </div>
        </v-tooltip>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#3F0E40" small depressed dark v-bind="attrs" v-on="on">
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <SingleProcess :item="item['request_id']" :idOfRate="item['id']" :rateRecord="item" @refreshAfterProccesses="refreshDataAfterProccesses"/>
          </v-list>
        </v-menu>
      </template>
    </new-table-component>
    <OrderRequestSection
        v-if="this.$store.state.openOrderRequestSection"
        style="margin-top: 50px"
    />

    <TasksRequestSection
        v-if="this.$store.state.openTasksRequestSection"
        style="margin-top: 50px"
    />

    <AddTaskForRequest
        v-if="this.$store.state.openAddTaskForRequest"
        style="margin-top: 50px"
    />

    <TasksDetailsRequestSection
        v-if="this.$store.state.openTasksDetailsRequestSection"
        style="margin-top: 50px"
    />
  </div>
</template>

<script setup>
import { ref, onMounted, watch, onBeforeUnmount,computed } from "vue";
import { axiosInstance } from "@/axios/config";
import SingleProcess from "./SingleProcess.vue";
import OrderRequestSection from "@/components/AdminComponent/OrderRequestSection.vue";
import TasksRequestSection from "@/components/AdminComponent/TasksRequestSection.vue";
import AddTaskForRequest from "@/components/AdminComponent/AddTaskForRequest.vue";
import TasksDetailsRequestSection from "@/components/AdminComponent/TasksDetailsRequestSection.vue";
import GroupProccess from "@/views/admin/Charts/DuplicatedCustomers/GroupProccess.vue";
import { store } from "@/store";
import { color } from "highcharts";

let loading = ref(false);
const headers = [
  { text: "تاريخ دخول الطلب للسله", value: "created_at", width: "120px",align: "center",},
  { text: "تاريخ الطلب", value: "request.req_date",align: "center" },
  { text: " اسم العميل", value: "request.customer.name",align: "center" },
  { text: "استشاري المبيعات", value: "request.user.name",align: "center" },
  { text: "تصنيف الاستشاري", value: "classification",align: "center" },
  { text: "ملاحظه الاستشاري", value: "request.comment", align: "center" },
  { text: "ملاحظه الجودة", value: "request.quacomment", width: "100px",align: "center" },
  { text: "مصدر المعامله", value: "old_request_source",align: "center" },
  { text: "مصدر محاوله التكرار", value: "request_source",align: "center" },
  { text: "يدوى - اتومتيك", value: "manual_auto",align: "center" },
  { text: "العمليات", value: "action",align: "center" },
];

const switchColor = ref({
  getAllRates: true,
  notProccessedFromSalesManager: false,
  ProccessedFromSalesManager: false,
  classificationTypeNegative: false,
  classificationTypePositive: false,
  ProccessedFromAdmin: false,
});

const page = ref(1);
const number_of_stars = ref(null);
const filterData = ref({});

watch(page, (current) => {
  getData(current, filterData.value);
});

watch(() => store.state.counterOfShow,
    (newVal, oldVal) => {
      if (newVal !== oldVal) {
        getData(page.value)
      }
    }
);

watch(number_of_stars, (newVal) => {
  handleStarsFilter(newVal);
});

const getHeaders = computed(() => {
  if (selectedTab.value === 1) {
    return headers.filter(header => header.value !== "manual_auto");
  }  else {
    return headers;
  }
});

const handleFilter = (data) => {
  filterData.value = data;
  filterData.classification_type = data;
  tableData.value = [];
  page.value = 1;
  getData(1, data);
};


const handleStarsFilter = (stars) => {
  filterData.value.stars = stars;
  getData(page.value, filterData.value);
};

const pagination = ref({});
const tableData = ref([]);
let selectedTab = ref(1);
let selectedTabTop = ref(0);


const getData = (count, data) => {
  loading.value = true;

  let body = new FormData();
  body.append("page", count);

  if (data) {
    for (const key in data) {
      if (data[key] !== "" && data[key] !== undefined && data[key] !== null) {
        body.append(key, data[key]);
      }
    }
  }

  if(selectedTab.value == 0){
    switchColor.value.getAllRates = true;
    body.delete(`is_processed`);
  }

  if(selectedTab.value == 1){
    switchColor.value.notProccessedFromSalesManager = true;
    body.append(`is_processed`,0);
  }

  if(selectedTab.value == 2){
    switchColor.value.ProccessedFromSalesManager = true;
    body.append(`is_processed`,1);
  }
  if(selectedTabTop.value == 0){
    switchColor.value.notProccessedFromSalesManager = true;
    body.append(`classification_type`,0);
  }

  if(selectedTabTop.value == 1){
    switchColor.value.ProccessedFromSalesManager = true;
    body.append(`classification_type`,1);
  }

  axiosInstance
      .post(`/employee/admin/duplicated-customers?per_page=${store.state.counterOfShow}`, body)
      .then((res) => {
        const response = res.data.payload;
        tableData.value = response.data;
        let paginationCount = null;
        // if (data) {
        //   paginationCount = response.searched_count;
        // } else {
        //   paginationCount = response.total_count;
        // }

        paginationCount = response.searched_count;
        pagination.value = {
          limit: 3,
          count: paginationCount,
        };
        loading.value = false;
      })
      .catch((err) => (loading.value = false));
};

const isProccessedBySalesManager = (status) => {
  return status != 0 ? `نعم` : `لا`;
};

const isProccessedByAdmin = (status) => {
  return status == 2 ? `نعم` : `لا`;
};

const extractTime = (rateDate) => {
  // Regular expression to match the time part
  const timeRegex = /\d{2}:\d{2} [AP]M/;
  // Extract the time part using regex
  const timeMatch = rateDate.match(timeRegex);
  // If match found, return the time part
  return timeMatch ? timeMatch[0] : '';
};



const tabsFilter = (value) => {
  selectedTab.value = value;
  getData(page.value,filterData.value)
};


const tabsFilterTop = (value) => {
  selectedTabTop.value = value;
  getData(page.value,filterData.value)
};


const refreshDataAfterProccesses = () => {
  getData(page.value,filterData.value)
};

onBeforeUnmount(() => {
  store.state.openOrderRequestSection = false;
});

let selected_requests = ref([]);

onMounted(() => {
  getData(1);
});
</script>

<style scoped>
.custom-tabs {
  border-bottom: 1px solid #ccc;
}

.custom-tab {
  color: white;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.custom-tab:hover {
  color: white;
}

.custom-tab-wrapper {
  background-color: #e3dce3;
  padding: 10px;
  margin: 5px;
  border-radius: 5px;
}

.custom-tabs-center-1 {
  display: flex !important;
  justify-content: right !important;
}

.custom-tabs-center-2 {
  display: flex !important;
  justify-content: left !important;
}

.down__arrow {
  position: absolute;
  bottom: -19px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 8px solid rgb(63, 14, 64);
  transform: translateX(-50%);
}

</style>
